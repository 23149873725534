/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = (props) => (
  <svg
    className='myxt-logo'
    sx={{
      height: props.size,
      width: props.size
    }}
    viewBox='0 0 277 233'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <title>Myxt - logo</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="myxtlogo-linearGradient-1">
        <stop stopColor="#EE4AD6" offset="0%"></stop>
        <stop stopColor="#ED9AE2" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="myxtlogo-linearGradient-2">
        <stop stopColor="#F77953" offset="0%"></stop>
        <stop stopColor="#F3F698" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="46.3788007%" x2="50%" y2="100%" id="myxtlogo-linearGradient-3">
        <stop stopColor="#F49254" offset="0%"></stop>
        <stop stopColor="#6CD8EA" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="98.4585918%" id="myxtlogo-linearGradient-4">
        <stop stopColor="#FF57E6" offset="0%"></stop>
        <stop stopColor="#6DD3EA" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-37.000000, -59.000000)">
        <g id="logo" transform="translate(37.000000, 59.000000)">
          <rect id="Left" fill="url(#myxtlogo-linearGradient-1)" x="0" y="0.904619728" width="91.4740741" height="231.274074" rx="45.737037"></rect>
          <rect id="Right" fill="url(#myxtlogo-linearGradient-2)" x="184.674074" y="0.904619728" width="91.4740741" height="231.274074" rx="45.737037"></rect>
          <path d="M253.547509,6.51186903 C274.213223,19.6886624 285.019643,45.2928314 266.935344,74.6239928 L187.013459,195.75944 C173.664554,223.564221 144.170435,239.537956 116.299429,223.564221 C88.4284216,207.590486 92.3533241,180.477705 109.969662,154.293308 L187.230111,27.3232277 C203.001829,1.13883151 232.881795,-6.6649243 253.547509,6.51186903 Z" id="Mid-right" fill="url(#myxtlogo-linearGradient-3)"></path>
          <path d="M160.228099,6.29693287 C181.175177,18.0935692 193.06414,42.236646 177.696357,68.2684606 L93.1037799,195.267869 C72.0389901,228.729722 45.4366149,235.603826 23.9793691,223.464991 C2.64683552,211.326157 -12.5760453,188.021819 16.1284621,151.309261 L94.4341292,29.5444379 C110.205848,3.51262318 139.281021,-5.49970341 160.228099,6.29693287 Z" id="Mid-left" fill="url(#myxtlogo-linearGradient-4)" transform="translate(92.509029, 115.121736) scale(-1, 1) translate(-92.509029, -115.121736) "></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
