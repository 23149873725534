/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

class Sash extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div
        style={{
          width: "100%",
          // height: "600px",
          overflow: "hidden",
          transform: "skewY(-8deg)",
          transformOrigin: 0,
          // background: `linear-gradient(
          //   45deg,
          //   #802aa5 30%,
          //   #2c3d46 84%,
          //   #3e3a1d 100%
          // )`,
          background: "rgba(245, 245, 245, 1)",
          position: "fixed",
          top: "-10px",
          bottom: "-300px",
          zIndex: "-1",
          "@media (maxWidth: 1100px)": `{
            height: 820px;
          }`,
          "@media (maxWidth: 800px)": `{
            height: 720px;
          }`,
          "@media (maxWidth: 500px)": `{
            height: 730px;
          }`,
          "@media (maxWidth: 400px)": `{
            height: 650px;
          }`,
        }}
      >
        {children}
      </div>
    )
  }
}

export default Sash
